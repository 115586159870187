export const service = {
	date: "August 3, 2023",
	intro: [
		"PLEASE READ THE FOLLOWING TERMS OF USE (“TERMS”) CAREFULLY BEFORE USING THE GENEMOD SERVICES. USE OF THE GENEMOD SERVICES WILL SIGNIFY YOUR AGREEMENT TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS DO NOT USE THE GENEMOD SERVICES."
	],
	body: [
		{
			title: "1. Agreement to Terms",
			paragraph: [
				"Access to and use of the website at <a href='https://www.genemod.net' target='_blank'>www.genemod.net</a> (‘Site’) and the data, content, products, and services made available on or via the Site (together with the Site the ‘Services’) are subject to the following Terms. By accessing the Site and/or using any other part of the Service, you accept and agree to be bound and abide by these Terms and our Privacy Policy found at <a href='https://www.genemod.net/privacy-policy' target='_blank'>www.genemod.net/privacy-policy</a>, incorporated herein by reference. If you do not want to agree to these Terms or our Privacy Policy, you must not access or use the Site or Services. The Services are offered and available to users who are 18 years of age or older. By using the Services, you represent and warrant that you are of legal age to form a binding contract with us. If you are not 18 years of age or older, you must not access or use the Services. If you are registering for or using the Services on behalf of an organization, you agree to these Terms for that organization and promise that you have the authority to bind that organization to these Terms. These Terms are effective upon the date you first access the Site (‘Effective Date’). If you have entered into a separate written agreement with us for use of the Services, the terms of such other agreement will prevail over any conflicting terms or conditions in these Terms with respect to the Services specified in such agreement."
			]
		},
		{
			title: "2. Modifications to these Terms",
			paragraph: [
				"We may modify the Terms upon notice to you at any time. These modifications may include, without limitation, eliminating the free accounts for individual users and requiring payment to use the Services. You will be provided notice of any such modification by email or by us publishing the changes on the Site’s Terms of Use. You will be provided the option to terminate your use of the Services if the Terms are modified in a manner that substantially affects your use of the Services. Your continued use of the Services after notice of any change to the Terms will be deemed to be your agreement to the amended Terms. If you terminate your use of the Services, no refunds will be issued. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you."
			]
		},
		{
			title: "3. License to Use the Services",
			paragraph: [
				"Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable right to access the Site and/or use the Services for your internal business purposes. This license is solely for enabling you to use and enjoy the benefit of the Services as provided by Genemod, in the manner permitted by these Terms. No part of the Site, nor any content, materials, or information on the Site, may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, or distributed in any way (including 'mirroring') to any other computer, server, website, or other medium for publication or distribution, without our express prior written consent."
			]
		},
		{
			title: "4. Accessing the Site and Account Security",
			paragraph: [
				"We reserve the right to withdraw or amend the Site in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site or the entire Site to users, including registered users.",
				"You are solely responsible for activity that occurs on your account and shall be responsible for maintaining the confidentiality of any password. You are responsible for both:",
				"- Making all arrangements necessary for you to have access to the Site;",
				"- Ensuring that all persons who access the Site through your internet connection are aware of these Terms and comply with them.",
				"To access the Site or use the Services, you may be asked to provide certain registration details or other information. It is a condition of your use of the Services that all the information you provide on the Site is correct, current, and complete. You agree that all information you provide to register with this Site or otherwise, including but not limited to through the use of any interactive features on the Site, is governed by our <a href='https://www.genemod.net/privacy-policy'>Privacy Policy</a>, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.",
				"If you choose or are provided with a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Site or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.",
				"We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms."
			]
		},
		{
			title: "5. Restrictions on Use",
			paragraph: [
				"You may use the Site only for lawful purposes and in accordance with these Terms. You agree not to use the Site:",
				"- In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).",
				"- For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.",
				"- To transmit or procure the sending of any advertising or promotional material without our prior written consent, including any 'junk mail', 'chain letter', 'spam', or any other similar solicitation.",
				"- To impersonate or attempt to impersonate Genemod, a Genemod employee, another user, or any other person or entity (including, without limitation, by using email addresses or usernames associated with any of the foregoing).",
				"- To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site, or which, as determined by us, may harm Genemod or users of the Site, or expose them to liability.",
				"Additionally, you agree not to:",
				"- Use the Site in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party's use of the Site, including their ability to engage in real-time activities through the Site.",
				"- Use any robot, spider, or other automatic device, process, or means to access the Site for any purpose, including monitoring or copying any of the material on the Site.",
				"- Use any manual process to monitor or copy any of the material on the Site or for any other unauthorized purpose without our prior written consent.",
				"- Use any device, software, or routine that interferes with the proper working of the Site.",
				"- Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.",
				"- Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Site, the server on which the Site is stored, or any server, computer, or database connected to the Site.",
				"- Attack the Site via a denial-of-service attack or a distributed denial-of-service attack.",
				"- Otherwise attempt to interfere with the proper working of the Site."
			]
		},
		{
			title: "6. Intellectual Property Rights",
			paragraph: [
				"The Site and its entire contents, features, and functionality (including but not limited to all information, software, compilation, digital conversion, text, displays, images, video, audio, and the design, selection, and arrangement thereof) are owned by us, our licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
				"These Terms permit you to use the Site for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Site, except as follows:",
				"- Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.",
				"- You may store files that are automatically cached by your web browser for display enhancement purposes.",
				"- You may print or download one copy of a reasonable number of pages of the Site for your own personal, non-commercial use and not for further reproduction, publication, or distribution.",
				"- You must not modify copies of any materials from this site.",
				"- You must not delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.",
				"You must not access or use for any commercial purposes any part of the Site or any services or materials available through the Site.",
				"If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Site in breach of the Terms, your right to use the Site will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Site or any content on the Site is transferred to you, and all rights not expressly granted are reserved by us. Any use of the Site not expressly permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws."
			]
		},
		{
			title: "7. Trademarks",
			paragraph: [
				"The name and logo of Genemod are registered trademarks of Genemod Corporation. You agree not to display or use in any manner the Genemod trademarks without our prior permission."
			]
		},
		{
			title: "8. Communications from Us",
			paragraph: [
				"We sometimes send users of the Services certain communications such as service announcements, administrative messages, and newsletters. You may opt-out from receiving newsletters from us but you may not opt-out from receiving service announcements and administrative messages."
			]
		},
		{
			title: "9. User Generated Content",
			paragraph: [
				"You may store, transmit, or publish content created by you using the Services (which you or your institution or employer may own, depending on the agreement you have with your institution or employer). However, you shall be solely responsible for such content and the consequences of its transmission or publication. Any content made public will be accessible through the internet and may be crawled and indexed by search engines. You are responsible for ensuring that you do not accidentally make any private content publicly available. Any content that you may receive from other users of the Services is provided to you 'AS IS' for your information and personal use only, and you agree not to use, copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit such content for any purpose without the express written consent of the person who owns the rights to such content. In the course of using the Services, if you come across any content with copyright notice(s) or any copy protection feature(s), you agree not to remove such copyright notice(s) or disable such copy protection feature(s), as the case may be. By making any copyrighted/copyrightable content available on the Services, you affirm that you have the consent, authorization, or permission, as the case may be, from every person who may claim any rights in such content to make such content available in such manner. You expressly agree that we will have the right to block access to or remove such content made available by you if we receive complaints concerning any illegality or infringement of third party rights in such content. By using the Services and transmitting or publishing any content using the Services, you expressly consent to the determination of questions of illegality or infringement of third party rights in such content by the agent designated by us for this purpose."
			]
		},
		{
			title: "10. Genemod’s Data Security; Disclaimer",
			paragraph: [
				"<b>The security of your information is important to us.</b> We take reasonable administrative, physical, and electronic measures designed to protect from unauthorized access, use, or disclosure of the information that we collect from you. Our servers are located in professional and secure hosting facilities designed to host servers with protection from unwanted attacks over the Internet and physical attacks to the building or server itself. In particular, the Site’s servers are in a private network with a dedicated firewall and are protected by round-the-clock interior and exterior surveillance. For physical security, our data centers are all SSAE-16 and/or ISO 27001 compliant. Our software infrastructure is regularly updated with the latest security patches. All access to the Site’s servers is protected by two-factor authentication and all traffic to our servers is encrypted as well.",
				"<b>We will notify you and try to fix any breaches of security.</b> If there is a suspected, threatened, or actual security incident or breach of security involving your information (a 'Security Breach'), we will at our own expense: (i) investigate and take all steps to identify, prevent, and mitigate the effects of such Security Breach; (ii) promptly notify you of the incident; and (iii) as soon as reasonably possible (a) conduct any recovery reasonably possible to remediate the impact of such Security Breach and (b) comply with applicable law and industry practices relating to such Security Breach. Despite the foregoing, you acknowledge and agree that this clause constitutes notice by us to you of the ongoing existence and occurrence or attempts of Unsuccessful Security Incidents for which no additional notice to you shall be required. 'Unsuccessful Security Incidents' means without limitation pings and other broadcast attacks on our firewall, port scans, unsuccessful login attempts, denial of service attacks, and any combination of the above, so long as no such incident results in unauthorized access, use, or disclosure of your information.",
				"<b>We back up your information.</b> We store all data in multiple secure locations and perform multiple daily backups of all critical data (including the database). We also test our backups in duplicate environments on a regular basis to ensure their correctness and to test disaster recovery scenarios. Database backups are audited daily.",
				"<b>You have security responsibilities.</b> You agree to: (i) keep your password and online ID secure and strictly confidential; (ii) notify us immediately and select a new online ID and password if you believe your password may have become known to an unauthorized person; and (iii) notify us immediately if you are contacted by anyone requesting your online ID and password. You understand and accept that you are responsible for any and all transactions performed under your account, even those transactions that are fraudulent or that you did not intend or want performed. You agree to indemnify us and hold us harmless from and against any and all liability arising in any way from the access to the Site using your online ID and/or online password.",
				"<b>Some third parties may have incidental access to your information.</b> We work with other companies to provide information technology services to users of the Site. These companies may have access to our databases but only for the purposes of providing service to us. For example, a third party may obtain access to your information in an effort to update database software or manage data. These companies will operate under consumer confidentiality agreements with us.",
				"<b>The Internet is not guaranteed to be safe.</b> Please be aware that no method of transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the absolute security of any information. WE SHALL HAVE NO LIABILITY TO YOU FOR ANY UNAUTHORIZED ACCESS, USE, CORRUPTION, OR LOSS OF ANY OF YOUR INFORMATION, EXCEPT TO THE EXTENT THAT SUCH UNAUTHORIZED ACCESS, USE, CORRUPTION, OR LOSS IS DUE SOLELY TO OUR GROSS NEGLIGENCE OR MISCONDUCT."
			]
		},
		{
			title: "11. FERPA (Family Educational Rights and Privacy Act of 1974)",
			paragraph: [
				"To the extent that the student data that we hold is subject to the Family Educational Rights and Privacy Act of 1974 (FERPA), we will comply with FERPA with respect to such information. FERPA provides guidelines for the handling, storage, and release of student data. It protects the privacy of education records, the right of a student to inspect and review his/her academic records, and the ability to amend records that are deemed inaccurate."
			]
		},
		{
			title: "12. Protected Health Information",
			paragraph: [
				"Despite anything to the contrary in these Terms, under no circumstances will you upload to the Site or otherwise provide to us any data or information which may qualify as protected health information (or any similar information) regulated under the Health Insurance Portability and Accountability Act of 1996 and related regulations (“HIPAA”) or the Health Information Technology for Economic and Clinical Health Act (“HITECH Act”) (all such data or information collectively “PHI”). GENEMOD EXPRESSLY DISCLAIMS ALL LIABILITY WITH RESPECT TO ANY SUCH PHI."
			]
		},
		{
			title: "13. Changes to the Site",
			paragraph: [
				"We may update the content on the Site from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Site may be out of date at any given time, and we are under no obligation to update such material. We reserve the right, at our sole discretion, to change, suspend, or discontinue any part of the Site at any time without notice to you."
			]
		},
		{
			title: "14. Third Party Data Storage",
			paragraph: [
				"If any part of the Service is made available on or through third party websites or other resources on the Internet, including links to such resources or to any part of the Service, then any access to, use of, and/or reliance upon such resources is done entirely at your own risk. We disclaim all liability arising therefrom.",
				"We will have no liability of any kind as a result of the deletion, modification, destruction, damage, loss of, or failure to store or encrypt any data which is stored on your or your institution’s local or third party storage systems."
			]
		},
		{
			title: "15. Inactive User Accounts",
			paragraph: [
				"We reserve the right to terminate free accounts that are inactive for a continuous period of 120 days. We will provide you prior notice of such termination by email."
			]
		},
		{
			title: "16. Suspension and Termination",
			paragraph: [
				"We reserve the right to suspend your account or temporarily disable access to all or part of the Services in the event of any suspected illegal activity, extended periods of inactivity, or requests by law enforcement or other government agencies. Objections to suspension or disabling of accounts should be made to <a href='mailto:genemod@genemod.net'>genemod@genemod.net</a>. within thirty (30) days of notification of the suspension or disabling. We may terminate a suspended or disabled account after thirty (30) days. In addition, we reserve the right to terminate your account upon reasonable belief that you have violated any of these Terms. We will also terminate your account upon your request. Termination of your account will include denial of access to all Services and deletion of your account information such as your email ID and password. All provisions of these Terms which by their nature should survive termination shall survive termination, including ownership provisions, warranty disclaimers, indemnities, and limitations of liability."
			]
		},
		{
			title: "17. Maintenance of Data Post Termination",
			paragraph: [
				"Upon termination of your access to the Services, we will maintain all of your data in a 'Read Only' format for a period of three (3) years. Access to your data post-termination may involve us sharing our proprietary information with you. In such an event, you will be required to sign a non-disclosure agreement so that we may protect our proprietary information."
			]
		},
		{
			title: "18. Warranty Disclaimer",
			paragraph: [
				"THE SERVICE IS PROVIDED 'AS IS' AND 'AS AVAILABLE' AND IS WITHOUT WARRANTY, CONDITION, OR GUARANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE FOREGOING, GENEMOD AND ITS DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS, INTEGRATORS, AND CONTENT PROVIDERS DO NOT WARRANT THAT: (A) THE SERVICE WILL BE SECURE OR AVAILABLE UNINTERRUPTED AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT AVAILABLE AT OR THROUGH THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK. THE FOREGOING PROVISIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW."
			]
		},
		{
			title: "19. Indemnification",
			paragraph: [
				"You shall defend, indemnify, and hold harmless us and our affiliates and our/their respective officers, directors, employees, or agents on demand from all losses, liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or access to, the Service; violation of these Terms; or infringement or violation caused by you or any third party using your account, of any Intellectual Property Rights or other right of any individual or entity. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you shall assist and cooperate with us in asserting any available defenses."
			]
		},
		{
			title: "20. Limitation of Liability",
			paragraph: [
				"IN NO EVENT SHALL WE, OUR AFFILIATES, SUPPLIERS, PARTNERS, LICENSORS, INTEGRATORS, OR CONTENT PROVIDERS, OR OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE WITH RESPECT TO THE SERVICE OR THE SUBJECT MATTER OF THIS AGREEMENT UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY: (I) FOR ANY AMOUNT IN EXCESS OF THE FEES PAID BY YOU FOR THE SERVICE DURING THE SIX (6)-MONTH PERIOD PRECEDING THE CLAIM, PROVIDED THAT THE FOREGOING WILL NOT LIMIT YOUR OBLIGATIONS TO PAY AMOUNTS DUE IN THE ORDINARY COURSE; (II) FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER; (III) FOR ANY LOSS OF PROFITS, REVENUE, BUSINESS, OR SAVINGS, DEPLETION OF GOODWILL AND/OR SIMILAR LOSSES, NOR FOR DATA LOSS OR COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; (IV) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION); OR (V) FOR ANY MATTER ARISING FROM YOUR BREACH OR NEGLIGENCE OR BEYOND OUR REASONABLE CONTROL. THE FOREGOING PROVISIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW."
			]
		},
		{
			title: "21. Governing Law; Dispute Resolution",
			paragraph: [
				"These Terms shall be governed by and construed in accordance with the laws of the State of Washington, excluding its conflicts of law provisions. Any dispute arising from or relating to the subject matter of these Terms shall be finally settled by binding arbitration in Snohomish County, Washington, using the English language in accordance with the Arbitration Rules and Procedures of Judicial Arbitration and Mediation Services Inc. ('JAMS') then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance with the Arbitration Rules and Procedures of JAMS. The prevailing party in any arbitration or other proceeding arising under these Terms shall be entitled to receive reimbursement of its reasonable expenses (including reasonable attorneys' fees, expert witness fees, and all other expenses) incurred in connection therewith. Judgment upon the award so rendered may be entered in a court having jurisdiction, or application may be made to such court for judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, each party shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable relief pending a final decision by the arbitrator. For all purposes of these Terms, the parties consent to exclusive jurisdiction and venue in the United States Federal Courts or state courts located in the District of Washington. Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions of these Terms, including without limitation this section."
			]
		},
		{
			title: "22. Miscellaneous",
			paragraph: [
				"<b>Entire Agreement.</b> These Terms are the entire agreement between you and Genemod with respect to the Site, Content, and other parts of the Service and supersede all prior or contemporaneous communications and proposals (whether oral, written, or electronic) between you and Genemod with respect to the same.",
				"<b>Severability.</b> If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms will otherwise remain in full force and effect and enforceable.",
				"<b>No Waiver.</b> The failure of either party to exercise any right provided for herein shall not be deemed a waiver of any further rights hereunder.",
				"<b>Force Majeure.</b> We shall not be liable for any failure to perform our obligations hereunder where such failure results from any cause beyond our reasonable control, including but not limited to labor disputes, strikes, lockouts, shortages of or inability to obtain energy, raw materials or supplies, denial of service or other malicious attacks, communications failure or degradation, material changes in law, war, terrorism, riot, or acts of God.",
				"<b>Assignment.</b> These Terms are personal to you and are not assignable, transferable, or sublicensable by you except with our prior written consent. We may assign, transfer, or delegate any of our rights and obligations hereunder without consent.",
				"<b>No Partnership or Joint Venture.</b> No agency, partnership, joint venture, or employment relationship is created as a result of these Terms and neither party has any authority of any kind to bind the other in any respect.",
				"<b>Notice.</b> All notices related to these Terms will be in writing and will be effective upon (a) personal delivery, (b) the second business day after mailing, or (c) except for notices of termination or an indemnifiable claim ('Legal Notices'), which shall clearly be identifiable as Legal Notices, the day of sending by email. Notice by mail will be addressed to Genemod Corporation, 8026 238th St. SW, Edmonds, WA 98026, and if by email to genemod@genemod.net."
			]
		},

	]
};
