import React from "react";
import { Typography, PageLayout, Link, PageMargins } from "@components";
import * as styles from "./index.module.scss";
import "./index.scss";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { useWindowSize } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";

type Content = {
	date?: string;
	intro: string[];
	body: {
		title: string;
		paragraph: string[];
	}[];
};

type PolicyPageProps = {
	pageName: 'privacy-policy' | 'terms-of-service' | 'msa';
	title: string;
	content: Content;
	withTableOfContents?: boolean;
	preBody?: React.ReactNode;
};

export default function PolicyPage({
	title,
	content,
	withTableOfContents = false,
	preBody,
	pageName,
}: PolicyPageProps) {
	const { isDesktop } = useWindowSize();

	const { date, intro, body } = content;

	const renderSectionTitle = (title: string) => (
		<Typography variant="BODY" className={styles.sectionTitle}>
			{title}
		</Typography>
	);

	const renderParagraphs = (keyPrefix: string, paragraphs: string[]) => (
		<Typography variant="BODY">
			{paragraphs.map((paragraph, i) => (
				<div
					className={styles.policyParagraph}
					key={keyPrefix + i}
					dangerouslySetInnerHTML={{ __html: paragraph }}
				></div>
			))}
		</Typography>
	);

	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: pageName }}
		>
			<BackgroundFadeContainer type="blueFade" withHeaderPadding>
				<Typography
					variant="HEADER1"
					color="ui-01"
					className={styles.pageTitle}
				>
					{title}
				</Typography>
			</BackgroundFadeContainer>
			<BackgroundShapes
				rightShape={{
					shape: (isDesktop && (
						<StaticImage
							loading="eager"
							src={
								"../../assets/images/logos/Genemod-watermark.png"
							}
							alt="genemod logo"
							width={338}
						/>
					)) || <></>,
					yOffset: -280,
					xOffset: -140,
				}}
			>
				<PageMargins fullWidth={false}>
					<div className={styles.policyPage}>
						{date && (
							<Typography variant="BODY">
								<p>Last updated: {date}</p>
							</Typography>
						)}

						<div className={styles.section}>
							{renderParagraphs("intro", intro)}
						</div>

						{withTableOfContents && (
							<div className={styles.section}>
								{renderSectionTitle("TABLE OF CONTENTS")}
								{body.map((policy, index) => (
									<Link
										link="PRIVACY_POLICY"
										suffix={"/#" + (index + 1)}
										key={index}
									>
										<Typography
											variant="BODY"
											color="brand"
											className={styles.link}
										>
											{policy.title}
										</Typography>
									</Link>
								))}
							</div>
						)}

						{preBody}
						<div className={styles.section}>
							{body.map(({ title, paragraph }, i) => (
								<div
									className={styles.mainContent}
									key={title + i}
								>
									<div
										className={styles.linkAnchor}
										id={i + 1 + ""}
									></div>
									{renderSectionTitle(title)}
									{renderParagraphs("body", paragraph)}
								</div>
							))}
						</div>
					</div>
				</PageMargins>
			</BackgroundShapes>
		</PageLayout>
	);
}
